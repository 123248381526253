import { domReady } from "../helpers"

domReady(function () {
  document.querySelectorAll("a.toggle-nlp-summary").forEach(function (el) {
    el.addEventListener("click", function (e) {
      console.log("hethere")
      e.preventDefault()
      document.querySelectorAll(".nlp-summary").forEach(function (elSummary) {
        elSummary.classList.toggle("hidden")
      })
    })
  })
})
