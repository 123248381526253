import { Controller } from "stimulus"
import { hasMatch } from "fzy.js"
import { debounce, nextFrame } from "../helpers"

export default class extends Controller {
  static targets = ["field", "links", "term", "filterable"]

  initialize() {
    this.filter = debounce(this.filter.bind(this), 60)

    this.linksTarget.addEventListener("hotkey-fire", (event) => {
      event.preventDefault()
      this.navigateItems(event.detail.path[0])
    })

    this.fieldTarget.addEventListener("keydown", (event) => {
      const key = event.key
      if (key === "ArrowDown") {
        event.preventDefault()
        this.navigateItems("ArrowDown")
      }
    })
  }

  navigateItems(key) {
    let index = this.focusable.indexOf(document.activeElement) || 0

    if (key === "ArrowUp") {
      if (index === 0) {
        index = this.focusable.length - 1
      } else {
        index--
      }
    } else if (key === "ArrowDown") {
      if (index === this.focusable.length - 1) {
        index = 0
      } else {
        index++
      }
    }
    this.focusable[index].focus()
  }

  async filter(event) {
    const query = event.target.value
    const match = (e) => hasMatch(query, e.dataset.search)
    const state = this.filterableTargets.map((e) => [e, match(e)])
    await nextFrame()
    if (query.length > 0) {
      this.linksTarget.hidden = false
      this.termTarget.innerHTML = query
    } else {
      this.linksTarget.hidden = true
    }
    for (const [element, match] of state) element.hidden = !match
    this.visibleActions = this.linksTarget.querySelectorAll("li:not([hidden]) :is(a, button)")
    this.focusable = [...this.visibleActions]
  }
}
