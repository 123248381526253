import { Controller } from "stimulus"
import { replaceElementWithResponse } from "../helpers"

export default class extends Controller {
  static classes = ["loading"]

  showLoadingState(event) {
    event.currentTarget.classList.add(this.loadingClass)
  }

  replaceWithResponse(event) {
    replaceElementWithResponse(this.element, event.detail.response)
  }
}
