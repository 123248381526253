import { persistResumableFields, restoreResumableFields, setForm } from "@github/session-resume"

addEventListener("submit", setForm, { capture: true })

addEventListener("pagehide", persistFields)
addEventListener("turbo:visit", persistFields)

addEventListener("pageshow", restoreFields)
addEventListener("turbo:load", restoreFields)

function persistFields() {
  persistResumableFields(location.pathname, { storage: localStorage })
}

function restoreFields() {
  restoreResumableFields(location.pathname, { storage: localStorage })
}
