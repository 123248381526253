import { Controller } from "stimulus"
import { nextFrame } from "../helpers"

export default class extends Controller {
  static values = { url: String }

  // Lifecycle

  connect() {
    this.disconnect()
    this.source = new EventSource(this.urlValue)
    this.source.addEventListener("output", (event) => this.render(event.data))
    this.source.addEventListener("done", () => this.disconnect())
  }

  disconnect() {
    this.source?.close()
    this.source = null
  }

  // Private

  async render(html) {
    await nextFrame()
    this.html = html
  }

  set html(html) {
    if (html == this.html) return
    this.element.innerHTML = html
    this.element.scrollIntoView({ block: "end" })
  }

  get html() {
    return this.element.innerHTML
  }
}
